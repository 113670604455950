



















































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Setting extends Vue {
  @Prop({ type: Object, default: () => ({}) }) readonly attrs: any;
  public refs!: { [x: string]: any };
  // private items = ["One", "Two", "Three"];
  // 语言选项列表
  get languageList() {
    return Object.keys(this.$i18n.messages);
  }
}
